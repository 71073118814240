@media (min-width: 992px) {

  .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    /*padding: 13px 20px;*/
    background: white;
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
    margin-left: 250px;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .layout-dashboard .ant-layout-header {
    margin: 0;
  }
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}

@media (min-width: 992px) {
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
}

@media (max-width: 768px) {
  .chat-component-input{
    position: fixed;
  }
  .chat-container-component{
    padding-bottom: 5.25rem;
  }
  .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
 .chat-row{
  padding-left:0;
  flex-direction: column-reverse;
  align-items: flex-start;
  width: 100%;
 }
}

body {
  --sb-track-color: rgba(255,255,255, 0);
  --sb-thumb-color: lightgrey;
  --sb-size: 7px;
}

body::-webkit-scrollbar {
  width: var(--sb-size)
}

body::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 5px;
  
}

@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: var(--sb-thumb-color)
                     var(--sb-track-color);
  }
}
