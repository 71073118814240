/*---------------------------Globa---------------------------
body {
    margin: 0;
    font-family: 'Josefin Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button{
    font-family: 'Josefin Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/
/*---------------------------Custom---------------------------*/

.kachel {
    background-color: white; /* Weiße Hintergrundfarbe */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Leichter Schatten */
    padding: 20px; /* Innenabstand */
    border-radius: 8px; /* Randradius für weichere Ecken */
}

.layout-dashboard .ant-layout-content {
    margin: 0 ;
}

/*---------------------------RBC---------------------------*/
/*
.rbc-allday-cell {
  display: none;
}*/

.rbc-event{
    padding: 5px !important;
    font-size: 2vh;
    display: flex !important;
    align-items: center !important;
}


.rbc-events-container {
    display: flex !important;
    align-items: center !important;
}

.rbc-event-content {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
}

.rbc-event-label {
    display: none !important;
}

.rbc-header {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;

}
.rbc-label{
    border-right: none !important;
}

.rbc-month-view {
    border-top: none !important;
}

.rbc-off-range {
    color: lightgray !important;
}

.rbc-off-range-bg {
    background: none !important;
}


.rbc-time-slot-group {
    border: none !important;
}
.rbc-time-view {
    border-top: none !important;
    border-bottom: none !important;
}