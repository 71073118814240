.popup-container {
    position: fixed;
    /*
    position: absolute;
    left: 50%;
    top: 50%;*/


    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    margin: auto!important;

}

.upload{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}