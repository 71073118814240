/* Popup.css */
.popup-container {
    position: fixed;
    /*
    position: absolute;
    left: 50%;
    top: 50%;*/


    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;

}

.popup > * {
    flex-basis: 100%;
}

.modal-addEvent {
    padding: 2%;
    width: 700px !important;
}

.row-upload {
    display: flex;
    flex-direction: column;
}

.row-participants {
    display: flex;
    flex-direction: column;
}

.row-navigate {

}

.inset-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px inset;
    padding: 5%;
    margin-bottom: 5%;
}

/*---------------------------------test----------------------------*/

.title {
    display: flex;
}

/*---------------------------------test----------------------------*/
.formButtons {
    display: flex;
    flex-direction: row-reverse;
}

.formButtons Button {
    margin-bottom: 5px;
    margin-left: 10px;
}

/* Apply backdrop blur effect */
.popup-container {
    backdrop-filter: blur(5px);
}